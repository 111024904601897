<template>
    <div class="loginPage" :loading="showLoading">
        <div class="content" v-if="!dialogVisible">
            <div class="title">智慧健康养老民政监管系统</div>
            <div class="login_box">
                <img src="../../assets/imgs/logo.png" alt="" /><br />
                <el-input class="myInput" placeholder="请输入用户名" v-model="userName" clearable>
                    <img class="myIcon" src="../../assets/imgs/user.png" slot="prefix" alt="" />
                </el-input>
                <el-input type="password" :show-password="true" class="myInput" placeholder="请输入密码" v-model="password">
                    <img class="myIcon" src="../../assets/imgs/lock.png" slot="prefix" alt="" />
                </el-input>
                <br />
                <el-button type="primary" class="myBtn" :loading="loading" @click="doLogin">登录</el-button>
            </div>
        </div>
        <div class="tip">©2022 慧年健康科技 (重庆) 有限公司</div>
        <!-- 关联账号 -->
        <el-dialog title="关联账号" :visible.sync="dialogVisible" width="700px" :show-close="false" :close-on-click-modal="false">
            <p>温馨提示：</p>
            <p>1、首次登录需将渝快政账号与此系统账号进行关联，账号关联成功之后，后续无需再关联，即可单点登录。</p>
            <p>2、若无此系统账号，请先联系管理员创建账号后再进行关联。</p>
            <el-steps direction="vertical" :active="2" style="height: 240px">
                <el-step title="确认渝快政账号信息">
                    <div slot="description" class="description">
                        <el-descriptions :column="1" style="margin-top: 10px">
                            <el-descriptions-item label="账号名">{{ bingUser.account }}</el-descriptions-item>
                            <el-descriptions-item label="姓名">{{ bingUser.name }}</el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-step>
                <el-step title="关联渝北家床系统账号">
                    <div slot="description" class="description">
                        <el-form :model="bindForm" :rules="rules" ref="bindForm" label-width="60px" style="width: 260px">
                            <el-form-item label="账号" prop="userName">
                                <el-input v-model="bindForm.userName"></el-input>
                            </el-form-item>
                            <el-form-item label="密码" prop="password">
                                <el-input type="password" v-model="bindForm.password" show-password></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-step>
            </el-steps>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="doBind">提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { login, ykzLogin, ykzBind } from '@/api/common'
import Watermark from '@/utils/waterMarkmore'
export default {
    // 登录
    name: 'login',
    data() {
        return {
            userName: '',
            password: '',
            loading: false,
            showLoading: false,
            bindForm: {
                userName: '',
                password: ''
            },
            rules: {
                userName: [{ required: true, message: '请输入账号', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
            },
            dialogVisible: false,
            bingUser: {
                account: '',
                accountId: '',
                name: ''
            }
        }
    },
    computed: {
        authCode() {
            console.log(this.$route, 'this.$routethis.$route')
            // return '48a34a0e72bf4f48b5f59fff849d060007ed0601'
            return this.$route.query.auth_code
        }
    },
    mounted() {
        localStorage.removeItem('scenarioList')
        localStorage.removeItem('mylist')
        localStorage.removeItem('initLeftMenu')
        localStorage.removeItem('currentIndex')
        this.$router.options.routes = [
            {
                path: '/login',
                name: 'Login',
                meta: { title: '登录', hideInMenu: true },
                component: () => import('@/views/Login/login.vue')
            },
            {
                path: '/404',
                component: () => import('@/views/error-page/404'),
                hidden: true
            }
        ]
        Watermark.out()
        // 渝快政免登陆
        if (this.authCode) {
            this.doNoLogin()
        }
    },
    methods: {
        // 登录
        doLogin() {
            this.loading = true
            login({ userName: this.userName, password: this.password })
                .then(res => {
                    this.loginSetInfo(res)
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
        // 免登陆
        doNoLogin() {
            this.showLoading = true
            ykzLogin({ authCode: this.authCode }).then(res => {
                if (res.state === 10006) {
                    this.dialogVisible = true
                    this.bingUser = res.data
                } else {
                    this.loginSetInfo(res)
                }
            })
        },
        // 渝快政绑定
        doBind() {
            this.$refs['bindForm'].validate(valid => {
                if (valid) {
                    ykzBind({ ...this.bindForm, accountId: this.bingUser.accountId }).then(res => {
                        this.loginSetInfo(res)
                    })
                }
            })
        },
        // 登录以后的操作
        loginSetInfo(res) {
            localStorage.setItem('cad_userInfo', JSON.stringify(res.data))
            this.$store.dispatch('permission/generateRoutes').then(res => {
                if (res.length) {
                    let path = res[0].children[0].children[0].path
                    let scenarioId = res[0].children[0].children[0].scenarioId
                    this.$router.push({ path: `${path}?${scenarioId}` })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.loginPage {
    width: 100%;
    height: 100%;
    background: url('../../assets/imgs/login_bg.png') no-repeat;
    background-size: 100% 73%;
    position: relative;
    .content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .title {
            font-size: 0.208333rem /* 40/192 */;
            color: #fff;
            text-align: center;
        }
        .login_box {
            width: 2.8125rem /* 540/192 */;
            height: 2.5rem /* 480/192 */;
            background: #ffffff;
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.16);
            border-radius: 0.104167rem /* 20/192 */;
            margin: 0.239583rem /* 46/192 */ auto;
            text-align: center;
            img {
                width: 0.541667rem /* 104/192 */;
                height: 0.541667rem /* 104/192 */;
                margin: 0.208333rem /* 40/192 */ auto;
                margin-bottom: 0.135417rem /* 26/192 */;
            }
            .myInput {
                width: 1.901042rem /* 365/192 */;
                ::v-deep {
                    input {
                        height: 0.260417rem /* 50/192 */;
                        line-height: 0.260417rem /* 50/192 */;
                        font-size: 0.083333rem /* 16/192 */;
                        border: none;
                        background: #efefef;
                        border-radius: 2px;
                    }
                }
                margin-bottom: 0.140625rem /* 27/192 */;
            }
            .myIcon {
                width: 0.104167rem /* 20/192 */;
                height: 0.104167rem /* 20/192 */;
                margin: 0;
                margin-top: 0.078125rem /* 15/192 */;
            }
            .myBtn {
                width: 1.901042rem /* 365/192 */;
                height: 0.260417rem /* 50/192 */;
                border-radius: 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                font-size: 0.104167rem /* 20/192 */;
            }
        }
    }
    .tip {
        position: absolute;
        left: 50%;
        bottom: 0.208333rem /* 40/192 */;
        font-size: 12px;
        transform: translateX(-50%);
    }
}
</style>
